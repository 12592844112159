// @file Logic related to opening pages in the native app.
import { currentHostname, transformCurrentUrl } from '@@/bits/location'
import { ORG_HOST } from '@@/bits/url'

const currentHost = currentHostname()
const appLinkHost = currentHost.endsWith(`.${ORG_HOST}`)
  ? currentHost.replace(`.${ORG_HOST}`, '-org.padlet.app')
  : 'padlet.app'

const appLink = transformCurrentUrl(
  {},
  {
    // TODO: Support other environments?
    host: appLinkHost,
    searchParams: { app_link: '1' },
  },
)

export { appLink, appLinkHost }
